import React from "react"
import { PerformanceShowPageProps } from "@richsoni/gatsby-theme-core/src/lib"
import { DownOutlined } from "@ant-design/icons"
import Layout from "../../../components/headerFooterLayout"
import LocationMap from "../../../components/LocationMap"
import { format, parseISO } from "date-fns"
import { navigate, graphql, Link } from "gatsby"
import { PlayCircleFilled, SoundFilled } from "@ant-design/icons"

import {
  Table,
  Button,
  Menu,
  Dropdown,
  Row,
  Col,
  Breadcrumb,
  Descriptions,
  Card,
} from "antd"

const StartEndTime = ({
  startTime,
  endTime,
  date,
}: PerformanceShowPageProps) => {
  const longDate = format(parseISO(date), "EEEE, LLL. d, yyyy")
  if (startTime || endTime) {
    return (
      <Descriptions title={longDate}>
        {startTime && (
          <Descriptions.Item key="starttime" label="Start Time">
            {startTime}
          </Descriptions.Item>
        )}
        {endTime && (
          <Descriptions.Item key="endtime" label="End Time">
            {endTime}
          </Descriptions.Item>
        )}
      </Descriptions>
    )
  } else {
    return <Descriptions title={longDate} />
  }
}

const SourceSelection = ({ sources, onChange, selectedSource }) => {
  if (sources && sources.length > 1) {
    const menu = (
      <Menu selectedKeys={[selectedSource]} onClick={e => onChange(e.key)}>
        {sources.map(source => (
          <Menu.Item key={source}>{source}</Menu.Item>
        ))}
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <button className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          Source: {selectedSource} <DownOutlined />
        </button>
      </Dropdown>
    )
  } else {
    return <div>Source: {selectedSource}</div>
  }
}

export default (props: PerformanceShowPageProps) => {
  const {
    as,
    date,
    endTime,
    hasSetlist,
    startTime,
    type,
    venueAddress,
    venueName,
    sources,
    selectedSource,
    onSourceChange,
    hasRecordings,
    tracks,
    location,
  } = props

  const setlist = tracks.length ? (
    <>
      <Card title="Setlist">
        <SourceSelection
          sources={sources}
          onChange={onSourceChange}
          selectedSource={selectedSource}
        />
        <Table
          style={{
            cursor: "pointer",
          }}
          showHeader={false}
          bordered={false}
          pagination={false}
          size="small"
          onRow={(record, index) => {
            return {
              onClick: event => {
                record.recordingID
                  ? record.onPlayClick()
                  : navigate(record.songSlug)
              },
            }
          }}
          dataSource={tracks}
          columns={[
            {
              title: "Recording",
              dataIndex: "recordingID",
              key: "recordingID",
              width: 10,
              render: (recordingID, record) => {
                if (recordingID) {
                  if (record.isPlaying) {
                    return <SoundFilled />
                  } else {
                    return <PlayCircleFilled />
                  }
                } else {
                  return <div />
                }
              },
            },
            {
              title: "Track Number",
              dataIndex: "trackNumber",
              key: "trackNumber",
              width: 10,
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Info",
              dataIndex: "songSlug",
              key: "songSlug",
              fixed: "right",
              render: songSlug => {
                return <Button onClick={() => navigate(songSlug)}>Info</Button>
              },
            },
          ]}
        />
      </Card>
      <div style={{ width: "100%", height: "2em" }} />
    </>
  ) : (
    <></>
  )
  return (
    <Layout
      style={{ padding: "24px", background: "#fff" }}
      currentPath={location.pathname}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/performances">Performances</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{venueName}</Breadcrumb.Item>
        <Breadcrumb.Item>{date}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ width: "100%", height: "2em" }} />
      <Row gutter={30} justify="space-between">
        <Col md={24} lg={18}>
          <Card title="Performance Details">
            <StartEndTime {...props} />
            <Descriptions title={type}>
              <Descriptions.Item key={as} label="Performing As">
                {as}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <div style={{ width: "100%", height: "2em" }} />
          {setlist}
        </Col>
        <Col md={24} lg={6}>
          <Card
            title="Venue Details"
            cover={
              <LocationMap addressString={`${venueName} - ${venueAddress}`} />
            }
          >
            <Card.Meta title={venueName} description={venueAddress} />
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}
