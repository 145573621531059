import React from "react"

export default ({ addressString }) => {
  const key = "AIzaSyCsl3C4pA6XgLeeFxghq3UkAIMs8kUt57A"
  return (
    <iframe
      title="Map"
      width="600"
      height="450"
      frameBorder="0"
      style={{ border: "0" }}
      src={`https://www.google.com/maps/embed/v1/place?key=${key}&q=${addressString}`}
      allowFullScreen={true}
    ></iframe>
  )
}
