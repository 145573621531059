export const asStr = as => {
  switch (as) {
    case "the-round":
      return "The Round";
    default:
      return "Rich Soni";
  }
};

export const typeStr = type => {
  switch (type) {
    case "open-mic":
      return "Open Mic Night Participant";
    case "open-mic-host":
      return "Open Mic Night Host";
    case "writers-in-the-round":
      return "Writers in The Round";
    case "support":
      return "Supporting Act";
    default:
      return "Live & Acoustic";
  }
};
